import React from 'react';
import PropTypes from 'prop-types';

const ExportAudienceModal = (props) => {
  const {
    error,
    success,
    audienceName,
    exportAudience,
    onChange,
    onClose,
  } = props;

  return (
    <>
      <div className="modal show" id="myModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Audience Name</h4>
              <button type="button" onClick={onClose} className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              {success && <p className="alert alert-success">{success}</p>}
              <label htmlFor="audience-name">
                Enter the name of the audience
                <input
                  id="audience-name"
                  type="text"
                  value={audienceName}
                  className="form-control"
                  onChange={onChange}
                />
              </label>
              {error && <span className="error">{error}</span>}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={exportAudience}
              >
                Export
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop show" />
    </>
  );
};

ExportAudienceModal.propTypes = {
  error: PropTypes.string.isRequired,
  success: PropTypes.string.isRequired,
  audienceName: PropTypes.string.isRequired,
  exportAudience: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExportAudienceModal;

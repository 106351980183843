import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Autocomplete from 'react-autocomplete';
import {
  saveMovieAction,
  deleteMovieAction,
  fetchReportsDispatch,
} from '../actions/ManagerActions';
import axios from '../utils/axios';
import SelectedAutocomplete from '../components/SelectedAutocomplete';

class MovieAutocomplete extends Component {
  static propTypes = {
    saveMovie: PropTypes.func.isRequired,
    deleteMovie: PropTypes.func.isRequired,
    fetchReports: PropTypes.func.isRequired,
    manager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      autocompleteMovies: [],
    };

    this.timer = null;

    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onChange(event, value) {
    this.setState({ value });

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(async () => {
      const response = await axios().get(`search/simple?term=${value}`);
      this.setState({
        autocompleteMovies: response.data.search.results,
      });
    }, 400);
  }

  async onDelete(index) {
    const { deleteMovie } = this.props;

    await deleteMovie(index);
    await this.reports();
  }

  async onSelect(value, item) {
    const { saveMovie } = this.props;

    await saveMovie(item);
    this.setState({ value: '', autocompleteMovies: [] });

    await this.reports();
  }

  async reports() {
    const { manager, fetchReports } = this.props;

    await fetchReports(manager);
  }

  static renderMenu(children) {
    return (
      <div className="menu">
        {children}
      </div>
    );
  }

  static renderItem(item, isHighlighted) {
    return (
      <div
        className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
        key={item.id}
      >
        <img src={item.poster_url} alt="poster" />
        <span>{item.title}</span>
      </div>
    );
  }

  render() {
    const { value, autocompleteMovies } = this.state;
    const { manager } = this.props;

    return (
      <div>
        <Autocomplete
          inputProps={{
            id: 'movies-autocomplete',
            className: 'form-control',
            placeholder: 'Search Movie or Show...',
          }}
          wrapperStyle={{ position: 'relative' }}
          value={value}
          items={autocompleteMovies}
          getItemValue={item => item.title}
          onSelect={this.onSelect}
          onChange={this.onChange}
          renderMenu={MovieAutocomplete.renderMenu}
          renderItem={MovieAutocomplete.renderItem}
        />
        <SelectedAutocomplete
          list={manager.movies}
          image="poster_url"
          title="title"
          deleteSelected={this.onDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ manager }) => ({ manager });

const mapDispatchToProps = dispatch => bindActionCreators({
  saveMovie: saveMovieAction,
  deleteMovie: deleteMovieAction,
  fetchReports: fetchReportsDispatch,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MovieAutocomplete);

import React from 'react';
import PropTypes from 'prop-types';
import Close from '../img/close.svg';

const SelectedAutocomplete = ({
  list,
  deleteSelected,
  image,
  title,
}) => list.map((item, index) => (
  /* eslint-disable react/no-array-index-key */
  <div className={`selected-tag${!image ? ' pill-tag' : ''}`} key={index}>
    {image && <img className="thumb" src={item[image]} alt="" />}
    <div className="selected-title">{item[title]}</div>
    <button className="remove" type="button" onClick={() => deleteSelected(index)}>
      <img src={Close} alt="" />
    </button>
  </div>
  /* eslint-enable react/no-array-index-key */
));

SelectedAutocomplete.defaultProps = {
  image: null,
};

SelectedAutocomplete.propTypes = {
  list: PropTypes.array.isRequired,
  deleteSelected: PropTypes.func.isRequired,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default SelectedAutocomplete;

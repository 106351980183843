import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Autocomplete from 'react-autocomplete';
import {
  savePeopleAction,
  deletePeopleAction,
  fetchReportsDispatch,
} from '../actions/ManagerActions';
import axios from '../utils/axios';
import SelectedAutocomplete from '../components/SelectedAutocomplete';

class PeopleAutocomplete extends Component {
  static propTypes = {
    savePeople: PropTypes.func.isRequired,
    deletePeople: PropTypes.func.isRequired,
    fetchReports: PropTypes.func.isRequired,
    manager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      autocompletePeoples: [],
    };

    this.timer = null;

    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  async onChange(event, value) {
    this.setState({ value });

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(async () => {
      const response = await axios().get(`search/contributors?term=${value}`);
      this.setState({
        autocompletePeoples: response.data.search.contributors,
      });
    }, 400);
  }

  async onDelete(index) {
    const { deletePeople } = this.props;

    await deletePeople(index);
    await this.reports();
  }

  async onSelect(value, item) {
    const { savePeople } = this.props;

    await savePeople(item);
    this.setState({ value: '', autocompletePeoples: [] });

    await this.reports();
  }

  async reports() {
    const { manager, fetchReports } = this.props;

    await fetchReports(manager);
  }

  static renderMenu(children) {
    return (
      <div className="menu">
        {children}
      </div>
    );
  }

  static renderItem(item, isHighlighted) {
    return (
      <div
        className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
        key={item.id}
      >
        {item.name}
      </div>
    );
  }

  render() {
    const { value, autocompletePeoples } = this.state;
    const { manager } = this.props;

    return (
      <div>
        <Autocomplete
          inputProps={{
            id: 'peoples-autocomplete',
            className: 'form-control',
            placeholder: 'Search People...',
          }}
          wrapperStyle={{ position: 'relative' }}
          value={value}
          items={autocompletePeoples}
          getItemValue={item => item.name}
          onSelect={this.onSelect}
          onChange={this.onChange}
          renderMenu={PeopleAutocomplete.renderMenu}
          renderItem={PeopleAutocomplete.renderItem}
        />
        <SelectedAutocomplete
          list={manager.peoples}
          title="name"
          deleteSelected={this.onDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ manager }) => ({ manager });

const mapDispatchToProps = dispatch => bindActionCreators({
  savePeople: savePeopleAction,
  deletePeople: deletePeopleAction,
  fetchReports: fetchReportsDispatch,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PeopleAutocomplete);

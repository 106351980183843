import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import {
  saveLocationAction,
  fetchReportsDispatch,
} from '../actions/ManagerActions';
import MapMarker from '../img/map-marker.svg';

class LocationAutocomplete extends Component {
  static propTypes = {
    saveLocation: PropTypes.func.isRequired,
    fetchReports: PropTypes.func.isRequired,
    manager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { address: '' };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  async handleSelect(address) {
    const { saveLocation, fetchReports } = this.props;
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      await saveLocation({
        ...latLng,
        address: results[0].formatted_address,
        miles: 15,
      });
      this.setState({ address: '' });

      const { manager } = this.props;
      await fetchReports(manager);
    } catch (e) {
      // continue regardless of error
    }
  }

  handleChange(address) {
    this.setState({ address });
  }

  render() {
    const { address } = this.state;

    return (
      <PlacesAutocomplete
        value={address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading,
        }) => (
          <div className="position-relative">
            <input
              {...getInputProps({
                id: 'locations-autocomplete',
                placeholder: 'Search...',
                className: 'form-control',
              })}
            />
            <div className="menu">
              {loading && <div className="item">Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'item item-highlighted'
                  : 'item';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <img src={MapMarker} className="location-marker" alt="marker" />
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

const mapStateToProps = ({ manager }) => ({ manager });

const mapDispatchToProps = dispatch => bindActionCreators({
  saveLocation: saveLocationAction,
  fetchReports: fetchReportsDispatch,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LocationAutocomplete);

import React from 'react';
import PropTypes from 'prop-types';

const EstimatedReach = ({
  matchedProfilesPartner, matchedProfilesSystem, startExport, message,
}) => (
  <div className="reach-card">
    <h3 className="">Estimated Reach</h3>
    {message && (
      <p>{message}</p>
    )}
    <div className="mt-3 h5">In Network</div>
    <h2>{matchedProfilesPartner || 0}</h2>
    <div className="mt-3 h5">Out of Network</div>
    <h2>{matchedProfilesSystem || 0}</h2>
    <button
      type="button"
      className="btn btn-brand btn-block"
      onClick={startExport}
    >
      Export
    </button>
  </div>
);

EstimatedReach.defaultProps = {
  matchedProfilesPartner: 0,
  matchedProfilesSystem: 0,
  message: null,
};

EstimatedReach.propTypes = {
  matchedProfilesPartner: PropTypes.number,
  matchedProfilesSystem: PropTypes.number,
  startExport: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default EstimatedReach;

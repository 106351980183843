import axios from '../utils/axios';
import { milesToMeters } from '../utils/general';
import env from '../config/env';

export const SAVE_MOVIE = 'SAVE_MOVIE';
export const DELETE_MOVIE = 'DELETE_MOVIE';

export const SAVE_PEOPLE = 'SAVE_PEOPLE';
export const DELETE_PEOPLE = 'DELETE_PEOPLE';

export const SAVE_GENRE = 'SAVE_GENRE';
export const DELETE_GENRE = 'DELETE_GENRE';

export const SAVE_LOCATION = 'SAVE_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';

export const SAVE_CAMPAIGN = 'SAVE_CAMPAIGN';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';

export const UPDATE_LOCATION_MILES = 'UPDATE_LOCATION_MILES';
export const SET_FOCUS_LOCATION = 'SET_FOCUS_LOCATION';

export const SAVE_STATS = 'SAVE_STATS';

export const saveMovieAction = data => ({
  type: SAVE_MOVIE,
  payload: data,
});

export const deleteMovieAction = data => ({
  type: DELETE_MOVIE,
  payload: data,
});

export const savePeopleAction = data => ({
  type: SAVE_PEOPLE,
  payload: data,
});

export const deletePeopleAction = data => ({
  type: DELETE_PEOPLE,
  payload: data,
});

export const saveGenreAction = data => ({
  type: SAVE_GENRE,
  payload: data,
});

export const deleteGenreAction = data => ({
  type: DELETE_GENRE,
  payload: data,
});

export const saveLocationAction = data => ({
  type: SAVE_LOCATION,
  payload: data,
});

export const deleteLocationAction = data => ({
  type: DELETE_LOCATION,
  payload: data,
});

export const saveCampaignAction = data => ({
  type: SAVE_CAMPAIGN,
  payload: data,
});

export const deleteCampaignAction = data => ({
  type: DELETE_CAMPAIGN,
  payload: data,
});

export const setFocusLocationAction = data => ({
  type: SET_FOCUS_LOCATION,
  payload: data,
});

export const updateLocationAction = (index, miles) => ({
  type: UPDATE_LOCATION_MILES,
  payload: {
    index,
    miles,
  },
});

export const saveStatsAction = data => ({
  type: SAVE_STATS,
  payload: data,
});

export const fetchReportsDispatch = manager => async (dispatch) => {
  const {
    movies,
    peoples,
    genres,
    locations,
    campaigns,
  } = manager;

  try {
    const res = await axios().get(`partners/${env.partnerId}/audience_definitions/count`, {
      params: {
        watchables: JSON.stringify(movies.map(movie => ({ type: movie.type, id: movie.id }))),
        contributorIds: peoples.map(people => people.id),
        genreIds: genres.map(genre => genre.id),
        locations: JSON.stringify(locations.map(location => ({
          lat: location.lat, lng: location.lng, radius: milesToMeters(location.miles),
        }))),
        campaigns: campaigns.map(campaign => campaign.name),
      },
    });
    dispatch(saveStatsAction(res.data));
  } catch (e) {
    // continue regardless of error
  }
};

import {
  SAVE_AUTH,
} from '../actions/AuthTokenActions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_AUTH:
      return Object.assign({}, state, {
        ...action.payload,
      });
    default:
      return state;
  }
};

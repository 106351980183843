import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  updateLocationAction,
  deleteLocationAction,
  fetchReportsDispatch,
} from '../actions/ManagerActions';
import Close from '../img/close.svg';

class Locations extends Component {
  static propTypes = {
    fetchReports: PropTypes.func.isRequired,
    updateLocation: PropTypes.func.isRequired,
    deleteLocation: PropTypes.func.isRequired,
    manager: PropTypes.object.isRequired,
  };

  async updateLocation(index, value) {
    const {
      updateLocation,
    } = this.props;

    await updateLocation(index, value);
    await this.reports();
  }

  async deleteLocation(index) {
    const {
      deleteLocation,
    } = this.props;

    await deleteLocation(index);
    await this.reports();
  }

  async reports() {
    const { manager, fetchReports } = this.props;

    await fetchReports(manager);
  }

  render() {
    const { manager: { locations } } = this.props;

    return (
      <div className="list-group mt-2">
        {locations.map((location, index) => (
          /* eslint-disable react/no-array-index-key */
          <div className="list-group-item d-flex align-items-center " key={index}>
            <span>{location.address}</span>
            <div className="ml-auto">
              <span className="d-inline-block">
                <input
                  className="form-control miles"
                  onChange={e => this.updateLocation(index, e.target.value)}
                  value={location.miles}
                />
              </span>
              <span className="ml-1">mi.</span>

              <span className="d-inline-block">
                <button type="button" className="removeLocations" onClick={() => this.deleteLocation(index)}>
                  <img src={Close} alt="" />
                </button>
              </span>
            </div>
          </div>
          /* eslint-enable react/no-array-index-key */
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ manager }) => ({ manager });

const mapDispatchToProps = dispatch => bindActionCreators({
  updateLocation: updateLocationAction,
  deleteLocation: deleteLocationAction,
  fetchReports: fetchReportsDispatch,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Locations);

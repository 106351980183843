import React from 'react';
import MovieAutocomplete from '../containers/MovieAutocomplete';
import PeopleAutocomplete from '../containers/PeopleAutocomplete';
import GenreAutocomplete from '../containers/GenreAutocomplete';
import LocationAutocomplete from '../containers/LocationAutocomplete';
import LocationPlots from '../containers/LocationPlots';
import Locations from '../containers/Locations';
import Campaigns from '../containers/Campaigns';
import Stats from '../containers/Stats';
import Logo from '../img/logo.png';

export default () => (
  <div>
    <div className="header">
      <img src={Logo} alt="logo" width="100" />
      <div className="header-title">Audience Manager</div>
    </div>
    <div className="container">
      <div className="row py-5">
        <div className="col-md-8">
          <div className="box">
            <div className="box-inner">
              <div className="my-2">
                <label htmlFor="movies-autocomplete">Movie or Show</label>
                <MovieAutocomplete />
              </div>
              <div className="my-2">
                <label htmlFor="peoples-autocomplete">People</label>
                <PeopleAutocomplete />
              </div>
              <div className="my-2">
                <label htmlFor="genres-autocomplete">Genres</label>
                <GenreAutocomplete />
              </div>
              <div className="my-2">
                <label htmlFor="locations-autocomplete">Locations</label>
                <LocationAutocomplete />
                <Locations />
                <div className="my-2">
                  <LocationPlots />
                </div>
              </div>
              <div className="my-2">
                <label htmlFor="campaign">Campaigns</label>
                <Campaigns />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="box">
            <div className="box-inner">
              <Stats />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

import {
  SAVE_PEOPLE,
  DELETE_PEOPLE,
  SAVE_MOVIE,
  DELETE_MOVIE,
  SAVE_GENRE,
  DELETE_GENRE,
  SAVE_LOCATION,
  DELETE_LOCATION,
  UPDATE_LOCATION_MILES,
  SET_FOCUS_LOCATION,
  SAVE_STATS,
  SAVE_CAMPAIGN,
  DELETE_CAMPAIGN,
} from '../actions/ManagerActions';

const initialState = {
  movies: [],
  peoples: [],
  genres: [],
  locations: [],
  campaigns: [],
  focusLocation: null,
  stats: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_MOVIE:
      return Object.assign({}, state, {
        movies: [action.payload, ...state.movies],
      });
    case DELETE_MOVIE:
      return Object.assign({}, state, {
        movies: state.movies.filter((movie, index) => index !== action.payload),
      });
    case SAVE_PEOPLE:
      return Object.assign({}, state, {
        peoples: [action.payload, ...state.peoples],
      });
    case DELETE_PEOPLE:
      return Object.assign({}, state, {
        peoples: state.peoples.filter((people, index) => index !== action.payload),
      });
    case SAVE_GENRE:
      return Object.assign({}, state, {
        genres: [action.payload, ...state.genres],
      });
    case DELETE_GENRE:
      return Object.assign({}, state, {
        genres: state.genres.filter((genre, index) => index !== action.payload),
      });
    case SAVE_LOCATION:
      return Object.assign({}, state, {
        locations: [action.payload, ...state.locations],
        focusLocation: {
          ...action.payload,
          index: 0,
        },
      });
    case DELETE_LOCATION:
      return Object.assign({}, state, {
        locations: state.locations.filter((location, index) => index !== action.payload),
        focusLocation: null,
      });
    case UPDATE_LOCATION_MILES: {
      const { index, miles } = action.payload;
      const locations = state.locations.slice();
      locations[index].miles = miles;

      return Object.assign({}, state, {
        locations,
        focusLocation: {
          ...locations[index],
          index,
        },
      });
    }
    case SET_FOCUS_LOCATION:
      return Object.assign({}, state, {
        focusLocation: action.payload,
      });
    case SAVE_CAMPAIGN:
      return Object.assign({}, state, {
        campaigns: [{ name: action.payload }, ...state.campaigns],
      });
    case DELETE_CAMPAIGN:
      return Object.assign({}, state, {
        campaigns: state.campaigns.filter((campaign, index) => index !== action.payload),
      });
    case SAVE_STATS:
      return Object.assign({}, state, {
        stats: action.payload,
      });
    default:
      return state;
  }
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { milesToMeters } from '../utils/general';
import axios from '../utils/axios';
import env from '../config/env';
import ExportAudienceModal from '../components/ExportAudienceModal';
import ListPreview from '../components/ListPreview';
import ExtimatedReach from '../components/ExtimatedReach';

class Stats extends Component {
  static propTypes = {
    manager: PropTypes.object.isRequired,
  };

  state = {
    askAudienceName: false,
    audienceName: '',
    error: '',
    success: '',
  };

  constructor(props) {
    super(props);

    this.exportAudience = this.exportAudience.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.startExport = this.startExport.bind(this);
  }

  onChange(e) {
    this.setState({ audienceName: e.target.value, error: '' });
  }

  onClose() {
    this.setState({ askAudienceName: false });
  }

  startExport() {
    this.setState({ askAudienceName: true });
  }

  async exportAudience() {
    const { audienceName } = this.state;
    const { manager } = this.props;
    const {
      movies,
      peoples,
      genres,
      locations,
      campaigns,
    } = manager;

    if (!audienceName) {
      this.setState({ error: 'You need an audience name!' });
      return;
    }

    try {
      const reqParams = {
        watchables: movies.map(movie => ({ type: movie.type, id: movie.id })),
        contributorIds: peoples.map(people => people.id),
        genreIds: genres.map(genre => genre.id),
        locations: locations.map(location => ({
          lat: location.lat, lng: location.lng, radius: milesToMeters(location.miles),
        })),
        campaigns: campaigns.map(campaign => campaign.name),
        name: audienceName,
      };

      const ADRes = await axios().post(`partners/${env.partnerId}/audience_definitions`, {
        audience_definition: reqParams,
      });

      await axios()
        .post(`partners/${env.partnerId}/audience_definitions/${ADRes.data.audience_definition.id}/export`, {
          targeting_platforms: ['facebook'],
        });

      this.setState({
        success: 'Exporting audience. You will receive an email shortly.',
        error: '',
        audienceName: '',
      });

      setTimeout(() => this.setState({
        success: '',
        askAudienceName: false,
      }), 3000);
    } catch (e) {
      this.setState({
        error: e.response.data.message || e.response.data.audience_definition.message,
      });
    }
  }

  render() {
    const {
      askAudienceName,
      error,
      success,
      audienceName,
    } = this.state;
    const { manager } = this.props;
    const {
      stats,
      movies,
      peoples,
      genres,
      locations,
      campaigns,
    } = manager;

    return (
      <div>
        <ListPreview
          list={movies}
          title="title"
          header="Movies"
        />
        <ListPreview
          list={peoples}
          title="name"
          header="Peoples"
        />
        <ListPreview
          list={genres}
          title="genre"
          header="Genres"
        />
        <ListPreview
          list={locations}
          title="address"
          header="Locations"
        />
        <ListPreview
          list={campaigns}
          title="name"
          header="Campaigns"
        />
        <ExtimatedReach
          matchedProfilesPartner={stats.matchedProfilesPartner}
          matchedProfilesSystem={stats.matchedProfilesSystem}
          message={stats.message}
          startExport={this.startExport}
        />
        {askAudienceName && (
          <ExportAudienceModal
            error={error}
            success={success}
            audienceName={audienceName}
            exportAudience={this.exportAudience}
            onChange={this.onChange}
            onClose={this.onClose}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ manager }) => ({ manager });

export default connect(mapStateToProps)(Stats);

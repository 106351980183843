import axios from '../utils/axios';
import env from '../config/env';
import { deleteToken, storeToken } from '../utils/general';

export const SAVE_AUTH = 'SAVE_AUTH';

export const authSaveAction = data => ({
  type: SAVE_AUTH,
  payload: data,
});

const checkPartner = partners => partners
  .map(partner => (typeof partner === 'object' ? parseInt(partner.id, 10) : partner))
  .includes(parseInt(env.partnerId, 10));

export const saveAuthDispatch = (data, history) => async (dispatch) => {
  try {
    storeToken(data.auth_token);
    const res = await axios().get('user/roles');

    dispatch(authSaveAction({
      authToken: data.auth_token,
    }));

    const authorized = checkPartner(res.data.user.partners || []);

    if (authorized) {
      history.push('/dashboard');
    } else {
      deleteToken();
      history.push('/?unauthorized');
    }
  } catch (e) {
    deleteToken();
    history.push('/?login');
  }
};

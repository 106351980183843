import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Autocomplete from 'react-autocomplete';
import {
  saveGenreAction,
  deleteGenreAction,
  fetchReportsDispatch,
} from '../actions/ManagerActions';
import axios from '../utils/axios';
import SelectedAutocomplete from '../components/SelectedAutocomplete';

class GenreAutocomplete extends Component {
  static propTypes = {
    saveGenre: PropTypes.func.isRequired,
    fetchReports: PropTypes.func.isRequired,
    manager: PropTypes.object.isRequired,
    deleteGenre: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      autocompleteGenres: [],
    };

    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.shouldItemRender = this.shouldItemRender.bind(this);
  }

  async componentDidMount() {
    const response = await axios().get('genres');

    this.setState({ autocompleteGenres: response.data.genres });
  }

  async onChange(event, value) {
    this.setState({ value });
  }

  async onSelect(value, item) {
    const { saveGenre } = this.props;

    await saveGenre(item);
    this.setState({ value: '' });

    await this.reports();
  }

  async onDelete(index) {
    const { deleteGenre } = this.props;

    await deleteGenre(index);
    await this.reports();
  }

  async reports() {
    const { manager, fetchReports } = this.props;

    await fetchReports(manager);
  }

  shouldItemRender(item) {
    const { manager: { genres } } = this.props;
    const { value } = this.state;

    return !genres.map(genre => genre.id).includes(item.id)
      && item.genre.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }

  static renderMenu(children) {
    return (
      <div className="menu">
        {children}
      </div>
    );
  }

  static renderItem(item, isHighlighted) {
    return (
      <div
        className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
        key={item.id}
      >
        {item.genre}
      </div>
    );
  }

  render() {
    const { value, autocompleteGenres } = this.state;
    const { manager } = this.props;

    return (
      <div>
        <Autocomplete
          inputProps={{
            id: 'movies-autocomplete',
            className: 'form-control',
            placeholder: 'Search Genre...',
          }}
          wrapperStyle={{ position: 'relative' }}
          value={value}
          items={autocompleteGenres}
          shouldItemRender={this.shouldItemRender}
          getItemValue={item => item.genre}
          onSelect={this.onSelect}
          onChange={this.onChange}
          renderMenu={GenreAutocomplete.renderMenu}
          renderItem={GenreAutocomplete.renderItem}
        />
        <SelectedAutocomplete
          list={manager.genres}
          title="genre"
          deleteSelected={this.onDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ manager }) => ({ manager });

const mapDispatchToProps = dispatch => bindActionCreators({
  saveGenre: saveGenreAction,
  deleteGenre: deleteGenreAction,
  fetchReports: fetchReportsDispatch,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GenreAutocomplete);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SelectedAutocomplete from '../components/SelectedAutocomplete';
import {
  saveCampaignAction,
  deleteCampaignAction,
  fetchReportsDispatch,
} from '../actions/ManagerActions';

class Campaigns extends Component {
  static propTypes = {
    saveCampaign: PropTypes.func.isRequired,
    deleteCampaign: PropTypes.func.isRequired,
    fetchReports: PropTypes.func.isRequired,
    manager: PropTypes.object.isRequired,
  };

  state = {
    value: '',
  };

  constructor(props) {
    super(props);

    this.save = this.save.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onChange(e) {
    this.setState({ value: e.target.value });
  }

  async onDelete(index) {
    const { deleteCampaign } = this.props;

    await deleteCampaign(index);
    await this.reports();
  }

  async save(e) {
    if (e.key === 'Enter') {
      const { saveCampaign } = this.props;

      await saveCampaign(e.target.value);
      this.setState({ value: '' });

      await this.reports();
    }
  }

  async reports() {
    const { manager, fetchReports } = this.props;

    await fetchReports(manager);
  }

  render() {
    const { value } = this.state;
    const { manager } = this.props;

    return (
      <div>
        <input
          value={value}
          type="text"
          className="form-control"
          onChange={this.onChange}
          onKeyPress={this.save}
          placeholder="Press enter to add..."
        />
        <SelectedAutocomplete
          list={manager.campaigns}
          title="name"
          deleteSelected={this.onDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ manager }) => ({ manager });

const mapDispatchToProps = dispatch => bindActionCreators({
  saveCampaign: saveCampaignAction,
  deleteCampaign: deleteCampaignAction,
  fetchReports: fetchReportsDispatch,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);

import React from 'react';
import PropTypes from 'prop-types';

const LivePreview = ({ list, header, title }) => (
  <>
    {list.length > 0 && <h5>{header}</h5>}
    <div className="list-group small-text">
      {list.map((item, index) => (
        /* eslint-disable react/no-array-index-key */
        <div className="list-group-item" key={index}>{item[title]}</div>
        /* eslint-disable react/no-array-index-key */
      ))}
    </div>
  </>
);

LivePreview.propTypes = {
  list: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default LivePreview;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, withRouter } from 'react-router-dom';
import Login from './Login';
import Dashboard from '../components/Dashboard';
import { saveAuthDispatch } from '../actions/AuthTokenActions';
import { deleteToken, getToken } from '../utils/general';
import Logo from '../img/logo.png';

class MainRoutes extends Component {
  static propTypes = {
    saveAuth: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    loading: true,
  };

  async componentDidMount() {
    const authToken = getToken();
    const { saveAuth, history } = this.props;

    if (authToken) {
      await saveAuth({ auth_token: authToken }, history);
    } else {
      deleteToken();
      history.push('/');
    }

    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return (
        <div className="login-container text-center">
          <div>
            <div className="text-center mb-3">
              <img src={Logo} width={200} alt="Noovie" />
            </div>
            <p className="text-muted">Loading...</p>
          </div>
        </div>
      );
    }

    return (
      <>
        <Route component={Login} path="/" exact />
        <Route component={Dashboard} path="/dashboard" exact />
      </>
    );
  }
}

const mapStateToProps = ({ authToken }) => ({ authToken });

const mapDispatchToProps = dispatch => bindActionCreators({
  saveAuth: saveAuthDispatch,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainRoutes));

import axios from 'axios';
import env from '../config/env';
import { getToken } from './general';

export default () => axios.create({
  baseURL: `${env.host}/api/v3`,
  headers: {
    'X-api-key': process.env.REACT_APP_NOOVIE_SERVICES_API_KEY,
    'X-auth-token': getToken(),
  },
});

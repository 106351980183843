import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveAuthDispatch } from '../actions/AuthTokenActions';
import Logo from '../img/logo.png';

class Login extends Component {
  static propTypes = {
    saveAuth: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.checkRole = this.checkRole.bind(this);
    this.loginWithNoovie = this.loginWithNoovie.bind(this);
  }

  loginWithNoovie() {
    window.NoovieConnect.login(this.checkRole);
  }

  async checkRole(response) {
    const { saveAuth, history } = this.props;

    await saveAuth(response, history);
  }

  render() {
    const type = window.location.search.substr(1);

    return (
      <div className="login-container text-center">
        <div>
          <div className="text-center mb-3">
            <img src={Logo} width={200} alt="Noovie" />
          </div>
          <div className="mb-2">
            <button type="button" className="btn btn-brand" onClick={this.loginWithNoovie}>
              Sign In With Noovie
            </button>
          </div>
          {type === 'unauthorized' && <div className="alert alert-danger">You&apos;re not authorized to access this</div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authToken }) => ({ authToken });

const mapDispatchToProps = dispatch => bindActionCreators({
  saveAuth: saveAuthDispatch,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);

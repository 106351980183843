import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
} from 'react-google-maps';
import { milesToMeters } from '../utils/general';

class Map extends Component {
  static propTypes = {
    focusLocation: PropTypes.object,
    zoom: PropTypes.number.isRequired,
    center: PropTypes.object.isRequired,
    places: PropTypes.array.isRequired,
  };

  static defaultProps = {
    focusLocation: null,
  };

  constructor(props) {
    super(props);

    this.map = null;
    this.circle = [];
  }

  componentDidUpdate() {
    const { focusLocation } = this.props;

    if (focusLocation) {
      this.map.fitBounds(this.circle[focusLocation.index].getBounds());
    }
  }

  render() {
    const {
      zoom,
      center,
      places,
    } = this.props;

    return (
      <GoogleMap
        google
        zoom={zoom}
        center={center}
        ref={(map) => {
          this.map = map;
        }}
      >
        {places.map((place, index) => (
          /* eslint-disable react/no-array-index-key */
          <Fragment key={index}>
            <Marker
              position={{
                lat: parseFloat(place.lat),
                lng: parseFloat(place.lng),
              }}
            />
            <Circle
              ref={(circle) => {
                this.circle[index] = circle;
              }}
              center={{
                lat: parseFloat(place.lat),
                lng: parseFloat(place.lng),
              }}
              radius={milesToMeters(place.miles)}
              options={{
                strokeColor: '#2678ff',
                fillOpacity: 0.2,
              }}
            />
          </Fragment>
          /* eslint-enable react/no-array-index-key */
        ))}
      </GoogleMap>
    );
  }
}

export default withGoogleMap(Map);

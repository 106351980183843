const development = {
  host: process.env.REACT_APP_NOOVIE_SERVICES_HOST,
  partnerId: process.env.REACT_APP_NOOVIE_PARTNER_ID,
};

const production = {
  host: process.env.REACT_APP_NOOVIE_SERVICES_HOST,
  partnerId: process.env.REACT_APP_NOOVIE_PARTNER_ID,
};

const common = {
  GKey: process.env.REACT_APP_GMAP_KEY,
};

export default {
  ...common,
  ...(process.env.NODE_ENV === 'production' ? production : development),
};

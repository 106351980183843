import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Map from '../components/Map';

class LocationPlots extends Component {
  static propTypes = {
    manager: PropTypes.object.isRequired,
  };

  getCenter() {
    const { manager: { locations, focusLocation } } = this.props;

    if (focusLocation) {
      return {
        lat: focusLocation.lat,
        lng: focusLocation.lng,
      };
    }

    if (locations.length !== 0) {
      return {
        lat: locations[0].lat,
        lng: locations[0].lng,
      };
    }

    return {
      lat: 38.18,
      lng: -100.87,
    };
  }

  render() {
    const { manager: { locations, focusLocation } } = this.props;

    const center = this.getCenter();

    return (
      <>
        <Map
          center={center}
          zoom={3}
          places={locations}
          focusLocation={focusLocation}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '400px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
        />
      </>
    );
  }
}

const mapStateToProps = ({ manager }) => ({ manager });

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LocationPlots);
